@import url('~normalize.css');

$fa-font-path: "/fonts/fontawesome-free/webfonts" !default;
@import "/node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "/node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "/node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

// NOTE: We're using a `.bp-` namespace to indicate these are the boilerplate
// styles.
@import './scss/settings';
@import './scss/fonts';
@import './scss/type';
@import './scss/layout';
@import './scss/widgets';
@import './scss/navigation';
@import './scss/other';