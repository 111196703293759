#department-layout {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
#department-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  height: 8rem;
}
#department-logo {
  display: block;
  height: 100%;
}
#department-logo .apos-areas-widgets-list,
#department-logo .apos-area-widget-wrapper,
#department-logo .apos-area-widget-inner,
#department-logo .apos-area,
#department-logo .bp-image-widget,
#department-logo img {
  height: 100%;
  aspect-ratio: 1/1;
}
#department-logo .apos-area-widget-inner > div:not(.apos-area-widget-controls) {
  height: 100%;
}
#department-photo > .apos-area {
  height: 100%;
}
#department-photo > .apos-area > .bp-image-widget {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}
#department-name h1 {
  margin: 0;
}
#department-socials {
  display: flex;
  height: 2.5rem;
}
#department-socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
}
#department-socials a:hover {
  background-color: #dddddd;
}
#department-socials a img {
  height: 75%;
}
#department-trainings > table {
  width: 100%;
  border-collapse: collapse;
}
#department-trainings > table tr.group-row {
  color: #fff;
  background-color: #333 !important;
  font-size: 1rem;
}
#department-trainings > table tr.group-row td {
  text-align: center !important;
  padding: 0.2rem;
}
#department-trainings > table td,
#department-trainings > table th {
  padding: 0.5rem;
  text-align: left;
}
#department-trainings > table th {
  background-color: #8C0917;
  color: #ffffff;
}
#department-groups {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "header header";
  column-gap: 2rem;
  row-gap: 1rem;
}
#department-groups .group img {
  width: 100%;
  object-fit: cover;
}
#department-groups .group-info {
  display: flex;
  height: 2rem;
}
#department-groups .group-info a {
  display: block;
  height: 100%;
  aspect-ratio: 1/1;
}
#department-groups > h2 {
  grid-row: 1;
  grid-area: header;
}