$header-height: 4rem;
$triangle-height: 5rem;

#page-content {
    display: grid;
    grid-template-columns: 1fr minmax(0, 800px) 1fr;

    grid-template-areas:
        "header-bg-l header header-bg-r"
        "departmentsAndCourses-bg-l departmentsAndCourses departmentsAndCourses-bg-r"
        "eventsAndSocial-bg-l eventsAndSocial eventsAndSocial-bg-r"
        "join-bg-l join join-bg-r"
        "contact-bg-l contact contact-bg-r";
}

#title {
    grid-column-start: header-bg-l;
    grid-column-end: header-bg-r;
}

#departments-and-courses {
    grid-column-start: departmentsAndCourses-bg-l;
    grid-column-end: departmentsAndCourses-bg-r;
}

#events-and-socials {
    grid-column-start: eventsAndSocial;
    grid-column-end: eventsAndSocial;
}

#join {
    grid-column-start: join;
    grid-column-end: join;
}

#contact {
    grid-column-start: contact;
    grid-column-end: contact;
}

section {
    width: 100%;

    .content {
        max-width: 50rem;
        margin: 0 auto;
    }

    &#title {
        // center title vertically in section
        display: flex;
        flex-direction: column;
        justify-content: center;

        // set height of container relative to viewport
        height: 25vh;
        min-height: 10rem;

        // padding for triangle overlay of the following section
        padding-bottom: 5rem;

        // put image in the background
        background-image: url("/images/background.webp");
        background-position: center;
        background-size: cover;
    }

    &#departments-and-courses {
        margin-top: -$triangle-height;

        .triangle {
            // needed to shift position later
            position: relative;

            // set the height of the triangles
            height: $triangle-height;

            // display as block to fix display issues
            display: block;
        }

        #top-triangle {
            // shift top triangle down to fix gap with following div
            top: 1px
        }

        #bottom-triangle {
            // shift bottom triangle up to fix gap with previous div
            top: -1px
        }

        .background {
            background-color: #5c060e;
        }
    }
}