#department-other-pages {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas: "header header";
    column-gap: 1rem;
    row-gap: 1rem;

    @media only screen and (max-width: 850px) {
        grid-template-columns: minmax(0, 1fr);
        grid-template-areas: "header";
    }

    &>h2 {
        grid-row: 1;
        grid-area: header;
    }

    &>.other-page {
        display: flex;
        gap: 1rem;

        &>.apos-area {
            &>img {
                width: 128px;
                height: 128px;
            }

            max-width: 8rem;
            aspect-ratio: 1/1;
            border-radius: 100%;
            overflow: hidden;


        }

        &>div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}