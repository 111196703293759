.socialmedia-grid {
    aspect-ratio: 1/1;

    display: grid;

    grid-template-rows: repeat(3, minmax(0, auto));
    grid-template-columns: repeat(3, minmax(0, auto));
    gap: 1rem;


    img {
        display: block;
        width: 100%;
    }
}