@import './scss/_modal.scss';
@import './scss/_newPage.scss';

.trainigs-table {
    tr.background-dark {
        background-color: #dddddd;
    }
}

.modal-open {
    cursor: pointer;
}