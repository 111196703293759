@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: url(/fonts/montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    src: url(/fonts/montserrat/Montserrat-Italic-VariableFont_wght.ttf) format('truetype');
}