#header {
  &>div {
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 800px;
  }

  & nav {
    display: none;
    align-self: stretch;
    align-items: end;
    justify-content: center;

    &>div {

      &>a,
      &>span {
        display: block;
        padding: 1rem;
        font-weight: bolder;
        color: white;
        font-size: 1.25rem;
      }

      &>span {
        cursor: default;
      }

      .submenu {
        display: none;

        position: absolute;
        padding: 0.5rem;
        margin: 0;
        margin-left: 1rem;
        list-style: none;

        background-color: #420007;

        &>li {
          padding: 0.25rem;

          &>a {
            color: white;
            font-weight: 600;
          }
        }

        &:hover {
          display: block;
        }
      }

      &:hover {
        & .submenu {
          display: block;
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  .menu-button {
    color: #ffffff;
    font-size: 3rem;
    padding: 0 1rem;
  }
}

#menu-overlay {
  display: grid;

  grid-template-rows: 6rem 1fr 8rem;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  width: 100%;
  height: 100%;

  background-color: #8C0917;

  ul {
    margin: 0 auto;
    list-style: none;
    font-size: 2.5rem;
    text-align: left;

    li {
      margin-top: 3rem;
    }

    a,
    span {
      cursor: pointer;
      color: #ffffff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  nav {
    padding: 0 1rem;
  }

  .next-button {
    width: 2rem;
    padding-left: 1rem;
  }

  .navigation-page {
    position: relative;

    padding-left: 0;

    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    transition-property: margin-left;
    transition-duration: 500ms;
    transition-timing-function: ease-out;

    &.show {
      margin-left: 0;
    }

    &.left {
      margin-left: -200%;
    }

    &.right {
      margin-left: 200%;
    }
  }

  #menu-container {
    overflow-y: scroll;
    overscroll-behavior: contain;
  }

  #menu-close {
    width: 4rem;
    display: block;
    margin: 0 auto;
  }

  #menu-close-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#menu-open {
  height: 4rem;
}

@media only screen and (min-width: 850px) {
  #menu-open {
    display: none;
  }

  nav {
    display: flex !important;
  }

  .menu-overlay {
    display: none;
  }
}