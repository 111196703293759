#departments-and-courses {
  a {
    color: white;
    text-decoration: underline;
  }

  a:visited {
    color: lightgray;
  }
}
