#group {
  &-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-photo {
    &>.apos-area {
      height: 100%;

      &>.bp-image-widget {
        max-height: 100%;
        width: 100%;

        object-fit: cover;
      }
    }
  }

  &-name {
    &>h1 {
      margin: 0;
    }
  }

  &-links {
    a {
      padding-left: 0.5rem, ;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-right: 1rem;

      display: inline-flex;
      align-items: center;
      gap: 1rem;
      border-radius: 0.5rem;

      cursor: pointer;

      &:hover {
        background-color: #dddddd;
      }

      img {
        height: 2rem;
      }
    }
  }

  &-trainings {
    &>table {
      width: 100%;
      border-collapse: collapse;

      & tr:nth-child(2n+1) {
        background-color: #dddddd;
      }

      & td,
      th {
        padding: 0.5rem;
        text-align: left;
      }

      & th {
        background-color: #8C0917;
        color: #ffffff;
      }
    }
  }
}

#group-contact-persons {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-areas: "header header";
  column-gap: 1rem;
  row-gap: 1rem;

  &>h2 {
    grid-row: 1;
    grid-area: header;
  }
}

@media only screen and (max-width: 850px) {
  #group-contact-persons {
    grid-template-columns: minmax(0, 1fr) !important;
    grid-template-areas: "header";
  }
}