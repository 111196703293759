html {
  position: absolute;
  width: 100%;
}

body {
  height: 100vh;
}

body,
[data-apos-refreshable] {
  display: flex;
  flex-direction: column;
}

[data-apos-refreshable],
.layout-wrapper {
  flex-grow: 1;
}

.layout-wrapper {
  display: grid;

  grid-template-rows: 4rem 1fr auto;
  grid-template-columns: 1fr minmax(auto, 800px) 1fr;

  grid-template-areas:
    "header-bg-l header header-bg-r"
    "main-bg-l main main-bg-r"
    "footer-bg-l footer footer-bg-r";
}

#header {
  grid-area: header;
  max-width: none;
  background-color: #8C0917;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: #fff;
  }
}

#header-bg-l {
  grid-area: header-bg-l;
}

#header-bg-r {
  grid-area: header-bg-r;
}

.header-footer-bg {
  display: block;
  width: 100%;
  background-color: #8C0917;
}

#main {
  grid-area: main;
}

#footer {
  grid-area: footer;
}

#footer-bg-l {
  grid-area: footer-bg-l;
}

#footer-bg-r {
  grid-area: footer-bg-r;
}

.bp-footer__links {
  padding-left: 0px;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    margin-right: 20px;
  }

  li:last-child {
    margin-right: 0;
  }
}

#logo {
  display: block;
  margin-top: 1rem;
  height: 4.5rem;
  max-width: 100%;
  padding-left: 2rem;
  object-fit: contain;

  filter: drop-shadow(0 0 1rem black);
}

.bp-header__login {

  &:visited {
    color: white;
  }
}

.apos-admin-bar-spacer {
  margin-bottom: 0 !important;
}

.columns-2 {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

#departments-and-courses {
  color: #fff;
  margin-top: 3rem;
  margin-bottom: 3rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0.5rem 0;
    }
  }
}

#breadcrumbs {
  padding: 1rem;
  padding-top: 2rem;

  a,
  span {
    padding: 0 1rem;
  }
}

.content {
  position: relative;
  margin: auto;

  width: 100%;
  box-sizing: border-box;

  padding-top: 2rem;
  padding-bottom: 2rem;

  max-width: 800px;
  padding-left: 1rem;
  padding-right: 1rem;
}

form {

  input,
  textarea {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 0.25rem solid #888888;
    box-sizing: border-box;
    max-width: 100%;
    min-width: 100%;
    min-height: 3rem;
  }

  input[type="submit"] {
    background-color: #bb7073;

    &:hover {
      background-color: #8c0a17;
      border-color: #444444;
      color: #fff;
    }
  }
}

@media(max-width: 850px) {
  .columns-2 {
    grid-template-columns: 1fr;
  }

  #courses {
    text-align: right;
  }
}

.hidden {
  display: none !important;
}

#background-image {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  min-height: 70vh;
  object-fit: cover;
  object-position: center;
}

#main-title {
  color: white;
  text-shadow: 0 0 1rem black;
}

footer {
  background-color: #8C0917;
  color: white;

  ul {
    list-style: none;
  }
}