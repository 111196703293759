.board {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));;
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
}

/*.person {
  width: 100%;
  display: flex;
  flex-direction: column;

  img {
    width: 75%;
    margin: 0 auto;
  }

  .name {
    margin-top: 0.5rem;
    font-weight: bold;
    font-size: 1.3rem;
    text-align: center;
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .responsabilites {
    margin-top: 2rem;
  }
}*/