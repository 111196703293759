#person-show-page #person-layout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#person-show-page #person-general {
  display: flex;
  gap: 1rem;
}
#person-show-page #person-general #name-and-position {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
#person-show-page #person-general #name-and-position > h1 {
  margin: 0;
  text-align: center;
}
#person-show-page #person-general #photo {
  width: 15rem;
  grid-area: photo;
  border-radius: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
}
#person-show-page #person-info {
  grid-area: info;
}
#person-show-page #person-groups {
  grid-area: groups;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 1rem;
}
#person-show-page #person-groups > h2 {
  grid-row: 1;
  grid-column: 1/span 2;
}
#person-show-page #person-groups > .group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
#person-show-page #person-groups > .group > .apos-area {
  width: 100%;
  height: 14rem;
  overflow: hidden;
}
#person-show-page #person-groups > .group img {
  object-fit: cover;
}