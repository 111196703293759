.person {
    &.small {
        display: flex;
        gap: 1rem;

        &>.apos-area {
            max-width: 8rem;
            border-radius: 100%;
            overflow: hidden;
        }

        &>div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.info {
                gap: 0.5rem;
            }
        }

        .contacts {
            display: flex;
            flex-direction: row;
        }
    }

    &.full {
        display: grid;
        grid-template-columns: minmax(8rem, 1fr) 2fr;
        grid-template-areas: 
            "Photo Name"
            "Info Info";
        row-gap: 1rem;

        &>.apos-area {
            display: flex;
            flex-direction: column;
            justify-content: center;

            grid-area: Photo;
            border-radius: 100%;
            overflow: hidden;
            aspect-ratio: 1/1;
        }

        &>.name {
            grid-area: Name;
            margin-left: 1rem;
            align-self: center;
        }

        &>.contacts {
            grid-area: Info;
        }
    }
}