#person {
  &-layout {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-general {
    display: flex;
    gap: 1rem;

    & #name-and-position {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      &>h1 {
        margin: 0;
        text-align: center;
      }
    }

    & #photo {
      width: 15rem;
      grid-area: photo;
      border-radius: 100%;
      aspect-ratio: 1/1;
      overflow: hidden;
    }
  }


  &-info {
    grid-area: info;
  }

  &-groups {
    grid-area: groups;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 1rem;

    &>h2 {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    &>.group {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      &>.apos-area {
        width: 100%;
        height: 14rem;
        overflow: hidden;
      }

      & img {
        object-fit: cover;
      }
    }
  }
}