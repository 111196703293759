.email {
  padding: 1rem 0;
}

.picture {
  img {
    border-radius: 100%;
    overflow: hidden;
    display: block;
  }
}