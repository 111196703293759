.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    z-index: 500;

    background-color: rgba($color: #000000, $alpha: 0.3);
}

.modal-positioner {
    width: 30rem;
    margin: auto;
    margin-top: 5rem;

    max-width: 90%;

    background-color: #fff;
    box-shadow: 0 1rem 1.5rem #000;
    border-radius: 0.5rem;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.75rem 1rem;
    border-bottom: 1px solid #666;
    font-weight: bold;

    button {
        background-color: #eee;
        border: none;
        width: 2rem;
        height: 2rem;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 1rem;

        &:hover {
            background-color: #bbb;
        }

        &:active {
            background-color: #999;
        }
    }
}

.modal-content {
    padding: 1rem;
}