a.iconLink {
    // do this to center the image inside the link container
    display: flex;
    justify-content: center;
    align-items: center;

    // display the link as a 2rem by 2rem box with slightly rounded corners
    height: 2rem;
    width: 2rem;
    border-radius: 0.5rem;

    // hover effect
    &:hover {
        background-color: #dddddd;
    }

    // here we scale down the image to fit inside the container
    img {
        height: 75%;
    }
}