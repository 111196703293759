.floating-image {
  filter: drop-shadow(0.5rem 1rem 0.2rem rgba(0, 0, 0, 0.3));
}

#image-1-basketball {
  position: absolute;
  top: 5rem;
  right: 4rem;

  width: 9rem;
}

#image-2-bike {
  position: absolute;

  top: 11rem;
  left: 13.5rem;

  width: 9rem;
}

#image-3-swimmer {
  position: absolute;

  top: 5.5rem;
  left: 15rem;

  width: 8rem;
}

@media only screen and (max-width: 850px) {
  #image-1-basketball {
    position: absolute;
    top: 23rem;
    left: calc(25% - 4rem);
    right: unset;
  }

  #image-2-bike {
    top: 11rem;
    right: calc(25% - 4rem);
    left: unset;
  }

  #image-3-swimmer {
    top: 7rem;
    right: calc(30% - 3rem);
    left: unset;
  }
}
