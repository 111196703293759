.bp-content {
    width: 100%;

    &>div {
        max-width: 800px;
        margin: auto;
        padding: 0 2rem;
    }
}

[data-apos-refreshable]:not(.edit-mode) {
    .show-in-edit-mode {
        display: none !important;
    }
}