a.iconLink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 0.5rem;
}
a.iconLink:hover {
  background-color: #dddddd;
}
a.iconLink img {
  height: 75%;
}