#department {
  &-layout {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    height: 8rem;
  }

  &-logo {
    display: block;
    height: 100%;

    .apos-areas-widgets-list,
    .apos-area-widget-wrapper,
    .apos-area-widget-inner,
    .apos-area,
    .bp-image-widget,
    img {
      height: 100%;
      aspect-ratio: 1/1;
    }



    .apos-area-widget-inner {
      &>div:not(.apos-area-widget-controls) {
        height: 100%;
      }
    }
  }

  &-photo {
    &>.apos-area {
      height: 100%;

      &>.bp-image-widget {
        max-height: 100%;
        width: 100%;

        object-fit: cover;
      }
    }
  }

  &-name {
    h1 {
      margin: 0;
    }
  }

  &-socials {
    display: flex;
    height: 2.5rem;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
      aspect-ratio: 1/1;
      border-radius: 2rem;

      &:hover {
        background-color: #dddddd;
      }

      img {
        height: 75%;
      }
    }
  }

  &-info {}

  &-trainings {
    &>table {
      width: 100%;
      border-collapse: collapse;

      tr.group-row {
        color: #fff;
        background-color: #333 !important;
        font-size: 1rem;

        td {
          text-align: center !important;
          padding: 0.2rem;
        }

      }

      & td,
      th {
        padding: 0.5rem;
        text-align: left;
      }

      & th {
        background-color: #8C0917;
        color: #ffffff;
      }
    }
  }

  &-groups {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: auto;
    grid-template-areas: "header header";
    column-gap: 2rem;
    row-gap: 1rem;

    .group {
      img {
        width: 100%;
        object-fit: cover;
      }

      &-info {
        display: flex;
        height: 2rem;

        a {
          display: block;
          height: 100%;
          aspect-ratio: 1/1;
        }
      }
    }

    &>h2 {
      grid-row: 1;
      grid-area: header;
    }
  }
}