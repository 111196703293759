.event {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    color: black;

    .calendar {
        width: 5rem;
        height: 5rem;
        box-shadow: 0 0 0.5rem #aaaaaa;
        border-top: 0.5rem solid red;

        .day {
            font-size: 3rem;
            width: 100%;
            text-align: center;
            display: inline-block;
        }

        .month {
            font-size: 0.7rem;
            font-weight: bold;
            width: 100%;
            text-align: center;
            display: inline-block;
        }
    }

    &.old {
        .calendar {
            border-top-color: #888888;
            color: #777777
        }
    }
}