.bp-video-widget {
  width: 100%;
}

.bp-image-widget {
  max-width: 100%;
}

.file-download {
  display: inline-block;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 2rem;
  background-color: #cccccc;
  color: black !important;
  cursor: pointer;
}
